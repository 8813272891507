.subheading {
  display: flex;
  gap: 1rem;
  color: rgb(65, 65, 65);
  // font-size: 20px !important;
  // font-weight: bold !important;
  letter-spacing: -0.2px;
  line-height: 25px;
  a {
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    letter-spacing: -0.3px;
    color: rgb(0, 100, 210);
  }
}

.description {
  // font-family: telstra-display-regular;
}
