.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > p {
    margin: 2.5rem 0;
  }
  > a:last-of-type {
    margin: 1.5rem 0;
  }
}

.create_service_link {
  margin-top: 1rem;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: -0.3px;
  color: rgb(0, 100, 210);
}
