@use "@able/web/src/index" as able;

$path: "../../assets/fonts/";
@import "@able/web/src/able-web.scss";
@import "@able/react/dist/able-react-without-LG.min.css";

//#######################
//    ABLE ALIASES
//#######################
// For mixins look in /node_modules/@able/web/patterns
// $TextStyle-names: HeadingDisplay, HeadingA, HeadingB, HeadingC, HeadingD,
// LabelA1, LabelB1, LabelC1, Subheading, BaseBig, Base, FinePrintA, FinePrintB,
// Tag, CallOut, Footnote;

.alias-Base {
  @include able.TextStyle("Base");
}

.alias-BaseBig {
  @include able.TextStyle("BaseBig");
}

.alias-CallOut {
  @include able.TextStyle("CallOut");
}

.alias-FinePrintA {
  @include able.TextStyle("FinePrintA");
}

.alias-FinePrintB {
  @include able.TextStyle("FinePrintB");
}

.alias-Footnote {
  @include able.TextStyle("Footnote");
}

.alias-HeadingA {
  @include able.TextStyle("HeadingA");
}

.alias-HeadingB {
  @include able.TextStyle("HeadingB");
}

.alias-HeadingC {
  @include able.TextStyle("HeadingC");
}

.alias-HeadingD {
  @include able.TextStyle("HeadingD");
}

//disable lg font size
@media screen and (min-width: 105rem) {
  .alias-HeadingA {
    font-size: 2.5rem; // Match md font size
  }

  .alias-HeadingB {
    font-size: 2rem; // Match md font size
  }

  .alias-HeadingC {
    font-size: 1.75rem; // Match md font size
  }

  .alias-HeadingD {
    font-size: 1.5rem; // Match md font size
  }
}

.alias-HeadingDisplay {
  @include able.TextStyle("HeadingDisplay");
}

.alias-LabelA1 {
  @include able.TextStyle("LabelA1");
}

.alias-LabelB1 {
  @include able.TextStyle("LabelB1");
}

.alias-LabelC1 {
  @include able.TextStyle("LabelC1");
}

.alias-Subheading {
  @include able.TextStyle("Subheading");
}

.alias-Tag {
  @include able.TextStyle("Tag");
}

.alias-MaterialBaseSecondary {
  background-color: able.color("materialBaseSecondary");
}

.alias-MaterialLevel04 {
  background-color: able.color("materialLevel04");
}

.alias-textBase {
  color: able.color("textBase");
}

//#######################
//   RESETS & DEFAULTS
//#######################

body {
  margin: 0;
  font-family: "Telstra Text Variable", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Remove default button styling
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

// Default table styling
table.no-internal-borders {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: left;
  border: 1px solid able.color("borderSubtle");
  border-radius: 4px !important;

  thead {
    background-color: #E7E8EE;

    th {
      height: 55px;
    }
  }

  tbody {
    font-size: small;

    &>tr {
      position: relative;
      cursor: pointer;
      height: 56px;

      &:nth-child(2n) {
        background-color: able.color("materialBaseSecondary");
      }

      &:hover {
        background-color: able.color("interactiveBackgroundHover") !important;
      }

      &:focus-within {
        box-shadow:
          0 0 0 0.1rem var(--focusedForeground) inset,
          0 0 0 0.25rem var(--focusedBorder) inset;
      }
    }
  }

  td,
  th {
    padding: 0 0.5em;
    border-top: 1px solid able.color("borderSubtle");
    border-bottom: 1px solid able.color("borderSubtle");
    border-left: none;
    border-right: none;
  }
}

// Skip links targets
.skip-link-target {
  scroll-margin-top: 3em;
  border-radius: 0.25rem;

  &:focus-visible {
    outline: var(--focusedForeground) solid 0.2rem;
  }
}

.page-heading-margins {
  margin-left: -var(--grid-margin);
  margin-right: -var(--grid-margin);
  width: calc(100% + 2 * var(--grid-margin)) !important;
  background-color: able.color("materialBaseSecondary") !important;
}

// Loading elipses
.loading {
  font-size: 18px;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

// Visually-hidden for accessibility
.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}