.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.success-icon,
.error-icon {
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
}

.alias-HeadingD {
    color: #000;
    text-align: center;
    font-family: "Telstra Text";
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
}

.success-message,
.error-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background: #F2F2F7;
    border-radius: 12px;
    flex-grow: 1;
    align-self: stretch;
}

.success-message p,
.error-message p {
    color: #131A35;
    text-align: center;
    font-family: "Telstra Text";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
}

.sheet-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sheet-content .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.action-button {
    width: 100% !important;
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.centered-button {
    display: flex;
    justify-content: center;
    width: 100%;
}