@use "@able/web/src/index" as able;

.background-overlay {
  opacity: 0;
  visibility: hidden;
  inset: 0px;
  background-color: rgb(0, 0, 0, 0.15);
  z-index: -1;
}

.drawer-container {
  z-index: 100;
  position: absolute;
  visibility: hidden;
  transform: translateX(-100%);
  background: #ffffff;
  min-width: 328px;
  height: 100%;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);

  h2 {
    margin: 0;
  }

  nav {
    width: 100%;
  }

  li > a,
  li > button {
    color: #414141 !important;
  }

  ol {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: center;

      button {
        text-align: left;
      }

      a,
      button {
        padding: 1rem 0;
        height: 100%;
        width: 100%;
        font-size: 18px;
        font-weight: normal;
        line-height: 19px;
        letter-spacing: -0.5px;
        text-indent: 2rem;
      }
    }

    li:hover {
      background-color: #eff6fe;
      transition:
        color 0.6s,
        background-color 0.6s;
      > a,
      button {
        color: #0064d2 !important;
      }
    }

    > .able-Divider {
      margin: 1rem 0.5rem;
    }
  }
}

.drawer-menu-heading {
  div {
    display: flex;
    align-items: center;
  }
  div > h2 {
    @include able.TextStyle("LabelA1");
    padding: 1rem 0;
    text-indent: 2rem;
  }
  li > a {
    @include able.TextStyle("Base");

    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.1px;
    line-height: 19px;
    text-indent: 3em !important;
  }
}

.show-drawer {
  opacity: 1;
  visibility: visible;
  transform: translateX(0%);
}

// If element has shadow-drawer AND background-overlay classname
.show-drawer.background-overlay {
  position: fixed;
  z-index: 99;
}

.show-drawer,
.drawer-container,
.show-overlay {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;
}

.drawer-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 0;
  background: #f4f4f4;
}

.mobile-nav {
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
}
