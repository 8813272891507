@use "@able/web/src/index" as able;

.abled-hyperlink-button {
    @include able.ActionButton(LowEmphasis);
    height: 48px;
    align-self: flex-end;
    text-decoration-line: underline;
    text-underline-offset: 4px;
}

.hyperlink-button-resize__false {
    @extend .abled-hyperlink-button;

    @include able.mediaQuery(breakpointLG) {
        font-size: 1rem;
    }
}

.hyperlink-button-resize__true {
    @extend .abled-hyperlink-button;
}

.inactive-link {
    text-decoration: none;
}