.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.loader {
  border: 16px solid #f3f6fd; /* Light grey */
  border-top: 16px solid #0164d2; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
