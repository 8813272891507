.flex-dl {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;

  dt {
    font-family: inherit;
  }

  dd {
    font-family: inherit;
  }
}

.information-container {
  position: relative;

  & dt > svg {
    position: absolute;
    margin-left: -40px;
  }

  dt,
  dd {
    margin-left: 40px;
  }
}
