.service-tier-sheet-contents {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;

  & button {
    margin-top: 2rem;
    flex: 1 1 0;
  }
}
