@use "@able/web/src/index" as able;

.greeting {
  margin-bottom: 16px;
  color: #414141;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.25px;
  line-height: 1.25;
  @media (max-width: 768px) {
    font-size: 34px;
  }
}

.grid-item--vxs-padding-bottom {
  @include able.mediaQuery(viewportXS) {
    padding-bottom: 1.5em;
  }
}

.para-light {
  font-size: 16;
  margin: 0;
}

.para-useful-link {
  color: #414141;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.1px;
  line-height: 1.25;
  margin-top: 41px;
  margin-bottom: 45px;
}

.para-bold {
  font-size: 20;
  margin: 0;
}

.custom-svg-class {
  width: 100%;
  height: 100%;
  //margin: 1;
  //line-height: 5px;
}

// Loading animation
.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 16px;
  width: 100px;
  margin: 0.5em 0;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}
