.table-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid hsl(0deg, 0%, 80%);

  & p {
    padding-left: 1rem;
  }

  & nav {
    padding-right: 1rem;
  }
}
