@use "@able/web/src/index" as able;

.info-list {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & dt {
    @include able.TextStyle("LabelA1");
  }

  & dd {
    @include able.TextStyle("Base");
  }
}
