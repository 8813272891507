.surface-count {
  padding: 0.5em 1em;
  margin: 0;
  height: 100%;
}

.surface-count__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.surface-count__header > h2 {
  font-size: 1.17rem;
}

.description-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid lightgray;
  padding: 1.25em 0.5em;
  margin-right: 1em;
}
