.flex-column-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (max-width: 1023px) {

  .form-button-group {
    display: flex;
    gap: 16px;
    bottom: 2rem;
    position: static;
    width: 100%;
    margin-top: 1rem;
  }
}

@media (min-width: 1023px) {
  .form-button-group {
    display: flex;
    gap: 16px;
    bottom: 2rem;
    position: absolute;
    width: 84%;

  }
}

.form-button-group .action-button {
  flex-grow: 1;
}

.user-role-label {
  letter-spacing: 0;
  font-size: 1rem;
  line-height: 1.5;
  font-family: Telstra Text Variable, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-variation-settings: "wght" 500, "slnt" 0;
  font-weight: normal;
}