[role="tab"] {
  padding: 1em;
  background: none;
  font-size: initial;
  border: none;
  border-bottom: 2px solid hsla(0, 0%, 65%);
  min-width: 8rem;
}

[role="tab"]:hover {
  border-bottom: 2px solid hsl(234, 65%, 50%);
}

[role="tab"][aria-selected="true"] {
  border-bottom: 2px solid hsl(234, 57%, 54%);
  box-shadow: inset 0px -2px 0px 0px hsl(234deg, 57%, 54%);
}

// Pinched from Able
// $haloActive: rgba(0, 100, 210, 0.16);
// $borderActive: #0064d2;

[role="tab"]:focus {
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.125rem var(--focusedForeground), 0 0 0 0.25rem var(--focusedBorder);
  outline: none;
}
