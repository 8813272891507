.help-contents {
  margin-top: 1rem;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 1rem;

  * > ul {
    padding: 0;
    margin-bottom: 0;
  }
}
