@use "@able/web/src/index" as able;

.input-container {
  @include able.TextField();
}

.error-message {
  display: block;
  margin: 0;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #d0021b;

  svg {
    margin-left: 0;
  }
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  gap: 1rem;
}
