$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-2xl: 1536px;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.form_container {
  display: flex;
  flex-direction: column;
  gap: 2.25em;
  min-height: 480px;
  padding: 5rem 0;
  @media (min-width: $breakpoint-sm) {
    width: 640px;
  }
}

.sequence {
  margin-top: auto;
}

.cancel {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}
