.navigation-bar {
  z-index: 50;
  display: flex;
  align-items: center;
  position: sticky;
  background-color: white;
  margin: 0px;
  padding: 0 1em;
  height: 56px;
  top: 0;
  border-image: linear-gradient(90deg, rgb(0, 100, 210), rgb(92, 214, 224), 60.56%, rgb(94, 80, 191)) 1;
  border-top: 2px solid;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.nav-container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  @media (max-width: 640px) {
    justify-content: flex-end;
  }
}

.nav-items {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  @media (min-width: 640px) {
    display: flex;
  }
  a {
    padding: 0 0.5rem !important;
  }
}

.nav-items {
  li {
    display: flex;
    align-items: center;
    height: 100%;
  }
  li:nth-last-child(2) {
    margin-left: auto;
  }
}

.nav-items,
ol,
ul {
  list-style: none;
}

.nav-items,
li {
  a {
    height: 100%;
    text-decoration: none !important;
  }
}

.nav-item {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.22px;
  line-height: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.nav-item-selected {
  font-weight: 500;
  border-bottom: 2px solid #0064d2;
  transition:
    color 0.6s,
    background-color 0.6s;
}

.dropdown .nav-item-selected {
  border-bottom: none;
  border-left: 2px solid #0064d2;
}

.nav-item-unselected {
  &:hover {
    color: #0064d2;
    background-color: #eff6fe;
  }
  transition:
    color 0.6s,
    background-color 0.6s;
}

.profile-icon-style {
  cursor: pointer;
}

.help-icon-style use {
  fill: black !important;
}

.help-icon-style {
  margin-right: 5px;
}

.dropdown {
  padding: 0;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-55%) !important;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  transition:
    visibility 0.25s ease-in-out,
    opacity 0.25s ease-in-out;
  li {
    a,
    button {
      padding: 0.5rem 1rem;
    }
  }
}

.dropdown-visible {
  visibility: visible;
  opacity: 1;
}

.button-reset {
  all: unset;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.button-reset:focus {
  outline: #b1dffd !important;
  border: 2px solid #005fcc;
  border-radius: 5px;
}

.mobile-nav-container {
  @media (min-width: 640px) {
    display: none;
  }
}

.highlighted-background {
  color: #0064d2;
  background-color: #eff6fe;
}

.hover {
  &:hover {
    @extend .highlighted-background;
  }
}

.ol-container {
  border: 1px solid black;
  position: absolute;
  z-index: -1;
  top: 3.5rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 3px 1px #00000036;

  & ol {
    padding: 0;
    display: flex;
    flex-direction: column;

    & li {
      flex-grow: 1;
      min-height: 2.5rem;
      min-width: 250px;
    }
  }

  & a {
    width: 100%;
    color: inherit;
  }
}

.active-link {
  box-shadow: 3px -1px 0px -1px #0064d2 inset;
  font-weight: 500;
}
