@use "@able/web/src/index" as able;

.logo {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none !important;
  margin: 0 80px 0 25px;
}

.tdn-heading {
  @include able.TextStyle(LabelA1);
  text-wrap: nowrap;
}

.telstra-icon {
  width: 25px;
  height: 25px;
}
