.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.current-recipients-button {
  margin-top: 1em !important;
}
