@use "@able/web/src/index" as able;

.top-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.25rem;

  @include able.mediaQuery(breakpointSM) {
    flex-direction: row;
  }
}

.select {
  @include able.mediaQuery(breakpointSM) {
    flex: 0 1 300px;
  }
}
