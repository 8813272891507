.chart-type-button {
  width: 66px;
  height: 40px;
  border: 1px solid rgb(0, 100, 210);
  border-radius: 20px;
  color: rgb(0, 100, 210);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0px;
  line-height: 22px;
}

.active-button-styles {
  width: 66px;
  height: 40px;
  background: rgb(0, 100, 210);
  border-radius: 20px;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0px;
  line-height: 22px;
}
