.table-controls {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: flex-end;

  .recipient-button {
    max-width: fit-content !important;
  }
}

.custom-row {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}