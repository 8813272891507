@use "@able/web/src/index" as able;

.service-status-container {
  display: flex;
  flex-direction: column;
}

.service-status-label {
  @include able.TextStyle("LabelA1");
}

.service-status-fineprint {
  @include able.TextStyle("FinePrintA");
  max-width: 420px;
}

.lozenge-group {
  display: flex !important;
  gap: 8px !important;
}

.service-status-buttons {
  display: flex;
  gap: 16px;
}
