.recipient-form-container {
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 20px;
  }
}

.recipient-form-subheading {
  color: rgb(65, 65, 65);
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.2px;
  line-height: 25px;
}

.recipient-form-custom-p {
  font-family: inherit;
}

.recipient-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.recipient-form-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5em;
}

.recipient-form-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
