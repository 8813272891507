.min-max-dl {
  min-width: 170px;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  
  & > dt {
    font-size: 3rem;
  }

  & > dd {
    margin: 0;
    margin-bottom: 1em;
  }
}

.mm-header {
  font-size: 36px;
}

.no-data-container {
  min-width: 170px;
  max-width: 320px;
  text-align: center;
  transform: translateY(25%);
}
